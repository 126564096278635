<template>
	<div class="WorkOrderUser">
		<div class="WorkOrderUser-T">
			<div class="WorkOrderUserL">
				<Breadcrumb></Breadcrumb>
				<div class="RightsManagement-searchs">

				</div>
			</div>
			<div class="WorkOrderUserR">
				<div class="RightsManagement-searchsMai">
					<!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
				</div>
			</div>
		</div>
		<div class="WorkOrderUser-B">
			<el-button v-permission="'WorkOrder:User:Add'" @click="clickNew()" icon="el-icon-plus"
				style="margin-bottom: 20px;">新增工单</el-button>
			<el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" border> <!-- :row-class-name="tableRowClassName" -->
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column type="index" label="序号" width="50" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="T_title" label="标题" width="250" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="T_text" label="详情" width="250" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column prop="CreateTime" label="时间" width="250" :show-overflow-tooltip="true">
				</el-table-column>
				<el-table-column label="状态" width="100" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						<el-tag
							:type="scope.row.T_State == 1 ? '' : scope.row.T_State == 2 ? 'danger' : scope.row.T_State == 3 ? 'success' : ''">
							{{ scope.row.T_State == 1 ? '已处理' :
								scope.row.T_State == 2 ? '待处理' :
									scope.row.T_State == 3 ? '已回访' : '' }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="address" label="操作" fixed="right">
					<template slot-scope="scope">
						<el-button v-permission="'WorkOrder:User:Get'" size="small"
							@click="SetBinding(scope.row)">查看</el-button>
						<!-- <el-button v-permission="'WorkOrder:User:Handle'" v-show="scope.row.T_State!=1" size="small" @click="SetEdit(scope.row)">已解决</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<div style="margin-top: 50px;">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page="pages.page" :page-size="pages.page_z" layout="total, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</div>

		</div>
		<el-dialog title="新增工单" :visible.sync="dialogVisible" :append-to-body="true">
			<div class="NewUser">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="60px" class="demo-ruleForm">
					<el-form-item label="名称" prop="T_title">
						<el-input v-model="ruleForm.T_title"></el-input>
					</el-form-item>
					<el-form-item label="详情">
						<el-input v-model="ruleForm.T_text"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
						<el-button @click="dialogVisible = false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-drawer title="查看工单" :visible.sync="drawer" direction="rtl" :append-to-body="true" size="50%">
			<div style="padding:0 20px;color: #000;">
				<timeline :workObj="workObj"></timeline>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import {
	WorkUserList,
	WorkUserAdd,
	WorkUserUp,
	WorkUserGet
} from "@/api/systemManagement/WorkOrderUser"
import timeline from '@/components/timeline'
export default {
	components:{timeline},
	data() {
		return {
			pages: {
				page: 1,
				page_z: 10,
			},
			total: 0,
			ruleForm: {
				T_title: '',
				T_text: '',
			},
			drawer: false,
			tableData: [],
			dialogVisible: false,
			rules: {
				T_title: [{
					required: true,
					message: '请输入名称',
					trigger: 'change'
				}],
			},
			workObj: {}
		}
	},
	mounted() {
		this.GetListApi()
	},
	methods: {
		// tableRowClassName({ row }) {
		// 	if (row.T_State == 1) {
		// 		return 'success-row';
		// 	}
		// 	if (row.T_State == 2) {
		// 		return 'error-row';
		// 	}
		// 	if (row.T_State == 3) {
		// 		return 'warning-row';
		// 	}
		// 	return '';
		// },
		GetListApi() {//列表
			WorkUserList(this.pages).then(res => {
				////console.log('列表',res)
				if (res.data.Code === 200) {
					this.tableData = res.data.Data.List
					this.total = res.data.Data.Num
				}
			})
		},
		SetListaddApip() {//添加工单
			return new Promise(resolve => {
				WorkUserAdd(this.ruleForm).then(res => {
					resolve(res)
				})
			})
		},
		clickNew() { //新增
			if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
				this.$refs.ruleForm.resetFields();
			}
			this.dialogVisible = true
		},
		submitForm(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					var res = await this.SetListaddApip()
					if (res.data.Code === 200) {
						this.dialogVisible = false
						this.pages.page = 1
						this.GetListApi()
						this.$message.success('操作成功，添加工单成功')
					}
				} else {
					return false;
				}
			});
		},

		handleClick(tab, event) {
			////console.log(tab, event)
		},


		async SetBinding(e) {//查看
			this.drawer = true
			var res = await this.GetshowApi(e)
			if (res.data.Code == 200) {
				this.workObj = res.data.Data
			}
		},
		GetshowApi(e) {//查看
			return new Promise(resolve => {
				WorkUserGet({ Id: e.Id }).then(res => {
					resolve(res)
				})
			})
		},
		SetEdit(e) {
			this.$confirm('此操作将更改解决状态为已解决, 是否继续?', '提示', {
				confirmButtonText: '我已解决',
				cancelButtonText: '我再想想',
				type: 'warning',
				center: true
			}).then(() => {
				WorkUserUp({ Id: e.Id, T_handle: 1 }).then(res => {
					////console.log('已经解决',res)
					if (res.data.Code == 200) {
						this.GetListApi()
						this.$message.success('操作成功，状态修改成功')
					}
				})
			}).catch(() => { });
		},
		handleSizeChange(e) { //每页多少条
			this.pages.page_z = e
			this.GetListApi()
		},
		handleCurrentChange(e) { //跳转页
			this.pages.page = e
			this.GetListApi()
		},
	}
}
</script>

<style lang="scss">
@import '@/assets/css/workOrder.scss';
.cs{
	height: 2px;
	position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background: repeating-linear-gradient(-45deg,var(--van-warning-color) 0,var(--van-warning-color) 20%,transparent 0,transparent 25%,var(--van-primary-color) 0,var(--van-primary-color) 45%,transparent 0,transparent 50%);
    background-size: 80px;
    content: "";
}
.el-table .warning-row {
	//已回访
	// background: #10FF00;
}

.el-table .error-row {
	//待处理
	background: rgb(246, 156, 156);
}

.el-table .success-row {
	//已解决
	background: #46C7FF;
}

.xian {
	border: 1px solid #DCDFE6;
	border-radius: 4px;
	padding: 0 15px;
	color: #606266;
	font-size: 14px;
}

.xianTxt {
	min-height: 200px;
	resize: vertical
}

.WorkOrderUser {
	.WorkOrderUser-T {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: rgba(#fff, 1);
		height: 70px;
		padding: 15px 10px;
		box-shadow: 5px 5px 10px -10px #000;

		.WorkOrderUserL {
			display: flex;
			align-items: center;

			.RightsManagement-searchs {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 20px;

				.WorkOrderUserit-mai1-btn {}

				.RightsManagement-searchs1 {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.RightsManagement-searchsMai {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}

	.WorkOrderUser-B {
		background: rgba(#fff, 1);
		margin: 10px;
		padding: 20px 30px;
	}
}</style>
